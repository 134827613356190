import domready from 'domready';
import {
  EventOptions,
  event as eventHandler,
  events as eventsHandler,
  action as actionHandler,
  actions as actionsHandler,
  bind as bindHandler,
} from './easyfy.core';
import InjectorFactory from './injectorFactory';
export { Idependencies } from './injectorFactory';

interface IEvent {
  target: string;
  type: string;
  meta?: object;
  options?: EventOptions;
}

interface IAction {
  target: string;
  type: string;
  meta?: object;
  options?: EventOptions;
}

interface IBind {
  target: string | Element | HTMLElement;
  type: string;
  meta?: object;
  options?: EventOptions;
}

export const invoke = function <T extends { new (...args: any[]): {} }>(ctor: T): void {
  let newClass: Object;

  const readyAfter = () => {
    // call class function onInit
    try {
      ctor?.prototype?.onInit?.apply(newClass);
    } catch (error) {
      console.log('Invoke onInit error', error);
    }
  };

  const init = (resolves?: Array<Object>) => {
    try {
      newClass = new ctor(...(resolves || []));
    } catch (err) {
      console.log('Error ', err);
    }

    if (ctor.prototype.hasOwnProperty('onInit')) {
      // Use the handy event callback
      domready(readyAfter);
    }
  };

  const depends = ctor['depends'];
  depends
    ? new InjectorFactory(ctor['depends'], (resolves) => {
        delete ctor['depends'];
        init(resolves);
      })
    : init();
};

export const event = (arg: IEvent) => {
  return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
    eventHandler({
      target: arg.target,
      type: arg.type,
      callback: actionEventHelper(descriptor, arg.meta).value,
      options: arg.options,
    });
  };
};

export const events = (arg: IEvent) => {
  return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
    eventsHandler({
      target: arg.target,
      type: arg.type,
      callback: actionEventHelper(descriptor, arg.meta).value,
      options: arg.options,
    });
  };
};

export const action = (arg: IAction) => {
  return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
    actionHandler({
      target: arg.target,
      type: arg.type,
      callback: actionEventHelper(descriptor, arg.meta).value,
      options: arg.options,
    });
  };
};

export const actions = (arg: IAction) => {
  return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
    actionsHandler({
      target: arg.target,
      type: arg.type,
      callback: actionEventHelper(descriptor, arg.meta).value,
      options: arg.options,
    });
  };
};

export const bind = (arg: IBind) => {
  return (_target: any, _key: string, descriptor: PropertyDescriptor) => {
    bindHandler({
      target: arg.target,
      type: arg.type,
      callback: actionEventHelper(descriptor, arg.meta).value,
      options: arg.options,
    });
  };
};

const actionEventHelper = (descriptor: PropertyDescriptor, meta?: object) => {
  const decorated: Function = descriptor.value;
  descriptor.value = function (event: Event) {
    if (event.defaultPrevented) {
      event?.preventDefault();
    }

    return decorated.apply(this, [...Array.from(arguments), meta]);
  };
  return descriptor;
};
