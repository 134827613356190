import { Id } from './easyfy.utils';

export default class InjectorFactory {
  dependencies: Object = {};
  resolveOnload: Array<string> = [];
  depends: Array<Idependencies>;
  doneCallback: Function;

  constructor(dependencies: Array<Idependencies>, finish?: Function, autoStart: boolean = true) {
    if (dependencies?.length > 0) {
      this.depends = dependencies;
      this.Transform();
    }

    if (finish) this.doneCallback = finish;

    if (autoStart) this.Resolve();
  }

  Transform() {
    this.depends.forEach((item) => {
      const id = Id(10);
      this.dependencies[id] = { name: id, func: new item.func(item.data || {}) };
      if (this.dependencies[id].func.hasOwnProperty('loaded')) this.resolveOnload.push(id);
    });
  }

  Resolve() {
    const _this = this;

    if (_this.resolveOnload.length > 0) {
      _this.resolveOnload.forEach((item, index) => {
        if (_this.dependencies[item].func.hasOwnProperty('loaded')) {
          const dependencyItem = _this.dependencies[item].func;
          let loadedVal = dependencyItem['loaded'];

          Object.defineProperty(dependencyItem, 'loaded', {
            get: () => {
              return loadedVal;
            },
            set: function (val) {
              if (val !== loadedVal) {
                if (val && dependencyItem) {
                  _this.resolveOnload.splice(index, 1);
                  delete dependencyItem['loaded'];

                  if (_this.resolveOnload.length === 0 && _this.doneCallback) {
                    let dependencyFunc: Array<Object> = [];
                    for (const key in _this.dependencies) {
                      if (_this.dependencies.hasOwnProperty(key)) {
                        const item = _this.dependencies[key];
                        dependencyFunc.push(item.func);
                      }
                    }

                    _this.doneCallback.call(this, dependencyFunc);
                  }
                }
              }
              loadedVal = val;
            },
            enumerable: true,
            configurable: true,
          });
        }
      });

      return false;
    }
    this.doneCallback?.call(_this);
  }
}

export interface Idependencies {
  func: { new (...args: any[]): {} };
  data?: any;
}