// exemple
// element('.about'); // <div class="about">
// element('[data-action="top-nav"]'); // <div class="nav" data-action="top-nav">
import domready from 'domready';
import { isString, hide } from './easyfy.utils';

//export const element  = (query: string) => document.querySelector(query);
export const element = (query: string) => document.querySelector<any>(query);

export const elements = (query: string) => document.querySelectorAll<any>(query);

export const elementsOf = (element: Element | HTMLElement, query: string) => element.querySelector<any>(query);

// exemple
// elementContains(document.querySelector('head'), document.querySelector('title')); // true
// elementContains(document.querySelector('body'), document.querySelector('body')); // false
export const elementContains = (parent: Element, child: Element) => parent !== child && parent.contains(child);

// ####################################
// #### Setup custom event handlers ####

interface BindArgs {
  target: string | Element | HTMLElement;
  type: string;
  callback: EventListener | EventListenerObject;
  options?: EventOptions;
}

interface ActionArgs {
  target: string;
  type: string;
  callback: EventListener | EventListenerObject;
  options?: EventOptions;
}

interface EventArgs {
  target: string;
  type: string;
  callback: EventListener | EventListenerObject;
  options?: EventOptions;
}

interface EventHandlerArgs {
  element?: Element | HTMLElement;
  type?: string;
  callback?: EventListener | EventListenerObject;
  options?: EventOptions;
}

export interface EventOptions {
  capture?: boolean;
  once?: boolean;
  passive?: boolean;
}

const addEventHandler = (event: EventHandlerArgs) =>
  event.element?.addEventListener(event.type, event.callback, mergeEventOptions(event.options));

const mergeEventOptions = (options?: EventOptions): EventOptions => Object.assign({ passive: true }, options || {});

export const on = (action: string | Element, type: string, callback: Function, ref?: object, meta?: object) => {
  domready(() => {
    type.split(' ').forEach(function (t) {
      (action instanceof Element ? action : element(action)).addEventListener(
        t,
        (e) => {
          try {
            callback.call(e.target, e, ref ?? null, meta ?? null);
          } catch (err) {}
        },
        { once: false, passive: true }
      );
    });
  });
};

export const one = (action: string | Element, type: string, callback: Function) => {
  domready(() => {
    type.split(' ').forEach(function (e) {
      (action instanceof Element ? action : element(action)).addEventListener(e, callback, { once: true });
    });
  });
};

export const event = (arg: EventArgs) => {
  domready(() => {
    addEventHandler({
      element: element(`[data-event="${arg.target}"]`),
      type: arg.type,
      callback: arg.callback,
      options: arg.options,
    });
  });
};

export const events = (arg: EventArgs) => {
  domready(() => {
    arg.type.split(' ').forEach((t) => {
      elements(`[data-event="${arg.target}"]`)?.forEach((item) => {
        addEventHandler({
          element: item,
          type: t,
          callback: arg.callback,
          options: arg.options,
        });
      });
    });
  });
};

export const action = (arg: ActionArgs) => {
  domready(() => {
    addEventHandler({
      element: element(`[data-action="${arg.target}"]`),
      type: arg.type,
      callback: arg.callback,
      options: mergeEventOptions(arg.options),
    });
  });
};

export const actions = (arg: ActionArgs) => {
  domready(() => {
    arg.type.split(' ').forEach((t) => {
      elements(`[data-action="${arg.target}"]`)?.forEach((item) => {
        addEventHandler({
          element: item,
          type: t,
          callback: arg.callback,
          options: mergeEventOptions(arg.options),
        });
      });
    });
  });
};

export const bind = (arg: BindArgs) => {
  domready(() => {
    if (arg.target instanceof HTMLElement || arg.target instanceof Element) {
      arg.type.split(' ').forEach((t) => {
        addEventHandler({
          element: arg.target as Element,
          type: t,
          callback: arg.callback,
          options: arg.options,
        });
      });
    }

    if (isString(arg.target)) {
      elements((arg.target as string) || '')?.forEach((item) => {
        arg.type.split(' ').forEach((t) => {
          addEventHandler({
            element: item,
            type: t,
            callback: arg.callback,
            options: arg.options,
          });
        });
      });
    }
  });
};

export const off = (
  action: string | Element | HTMLElement | Array<string | Element | HTMLElement>,
  type: string,
  callback: EventListener | EventListenerObject
) => {
  if (action instanceof String || action instanceof Element || action instanceof HTMLElement) {
    (action instanceof Element || action instanceof HTMLElement ? action : element(<string>action)).removeEventListener(
      type,
      callback,
      false
    );
  }

  if (action instanceof Array) {
    for (let x of action) {
      let item: any = x;
      (item instanceof Element || item instanceof HTMLElement ? item : element(<string>item)).removeEventListener(
        type,
        callback,
        false
      );
    }
  }
};

export const offAll = (action: string, type: string, callback: EventListener | EventListenerObject) => {
  elements(action).forEach((item) => {
    item.removeEventListener(type, callback, false);
  });
};

// #####################
// #### Event chain ####

export interface IEventChain {
  target: string;
  type: string;
  events: Array<IEventChainItem>;
  options?: EventOptions;
}

export interface IEventChainItem {
  action?: string;
  event?: string;
  callback?: Function;
}

export const eventChain = (arg: IEventChain) => {
  bind({
    target: arg.target,
    type: arg.type,
    callback: (e: Event) => {
      const target: any = e.target;
      let closestElement: HTMLElement;
      let eventTarget = target?.attributes['data-action']?.value ?? target?.attributes['data-event']?.value;
      if (!eventTarget)
        eventTarget =
          target.parentNode?.attributes['data-action']?.value ?? target.parentNode?.attributes['data-event']?.value;

      if (!eventTarget) return;

      e.preventDefault();

      let item: any = arg.events.find(({ action, event }) => (action ?? event).includes(eventTarget));

      if (!item) {
        item = arg.events.find(({ action, event }) => {
          const dataName: string = action ? 'action' : 'event';
          const dataTarget: string = action ? action : event;
          closestElement = target.closest(`[data-${dataName}="${dataTarget}"]`);
          return closestElement;
        });
      }

      if (item?.callback) item.callback.call(closestElement ? closestElement : e.target, e);
      return false;
    },
    options: arg.options,
  });
};

// ##############################
// #### Global event handler ####

export interface IEvent {
  type: string;
  event?: IEventItem;
  events?: Array<IEventItem>;
}

export interface IEventItem {
  action?: string;
  event?: string;
  callback?: Function;
}

interface IGlobalEventItem {
  type: string;
  action?: string;
  event?: string;
  callback?: Array<Function>;
}

// Global event
export let globalEventList: Array<IGlobalEventItem> = [];

export const globalEvent = (event: IEvent) => {
  event.type.split(' ').forEach((type) => {
    if (event.event) event.events = [...(event.events ?? []), ...[event.event]];

    if (event.events) {
      for (let item of event.events) {
        let foundItem;

        if (item?.action) {
          let actionFound: IGlobalEventItem = globalFindAction(type, item?.action);
          if (actionFound) {
            foundItem = actionFound;
          }
        }

        if (item?.event) {
          let eventFound: IGlobalEventItem = globalFindEvent(type, item?.event);
          if (eventFound) {
            foundItem = eventFound;
          }
        }

        if (!item?.event && !item?.action) {
          // check global state.
          let globalFound: IGlobalEventItem = globalFind(type);
          if (globalFound) {
            foundItem = globalFound;
          }
        }

        foundItem ? foundItem.callback.push(item.callback) : globalEventAdd(type, item);
      }
    }

    document?.body?.removeEventListener(type, globalEventHandler);

    domready(() => {
      document?.body?.addEventListener(type, globalEventHandler, { passive: false });
    });
  });
};

const globalEventHandler = (e) => {
  const target: any = e.target;

  // check action
  let eventTarget = globalGetTargetAction(target);
  if (eventTarget) {
    const item: IGlobalEventItem = globalFindAction(e.type, eventTarget);
    if (item?.callback) {
      globalEventInvokeCallback(item?.callback, e);
    }
    return;
  }

  // check event
  eventTarget = globalGetTargetEvent(target);
  if (eventTarget) {
    const item: IGlobalEventItem = globalFindEvent(e.type, eventTarget);
    if (item?.callback) {
      globalEventInvokeCallback(item?.callback, e);
    }
    return;
  }

  // check event
  const item = globalMatchType(e.type);
  if (item?.callback) {
    globalEventInvokeCallback(item?.callback, e);
    return;
  }
};

const globalEventInvokeCallback = (cbs: Array<Function>, e: any) => {
  for (let item of cbs) {
    item?.call(null, e);
  }
};

const globalEventAdd = (type: string, item: IEventItem): void => {
  globalEventList.push({
    type: type,
    action: item?.action,
    event: item?.event,
    callback: [item?.callback],
  });
};

const globalFind = (itemType: string): IGlobalEventItem => {
  return globalEventList.find(({ type, action, event }) => type === itemType && !action && !event);
};

const globalFindEvent = (itemType: string, eventType: string): IGlobalEventItem =>
  globalEventList.find(({ type, event }) => type === itemType && (event ?? '') === (eventType ?? ''));

const globalFindAction = (itemType: string, actionType: string): IGlobalEventItem =>
  globalEventList.find(({ type, action }) => type === itemType && (action ?? '') === (actionType ?? ''));

const globalMatchType = (itemType: string): IGlobalEventItem =>
  globalEventList.find(({ type, action, event }) => type === itemType && !action && !event);

const globalGetTargetAction = (target) => globalGetTarget(target, 'data-action');

const globalGetTargetEvent = (target) => globalGetTarget(target, 'data-event');

const globalGetTarget = (target, dataTarget: string) => {
  let eventTarget = target?.attributes[dataTarget]?.value;
  if (!eventTarget) eventTarget = target.parentNode?.attributes[dataTarget]?.value;
  return eventTarget;
};
