import axios from 'axios';
import { getTenant } from '../shared/easyfy.utils';
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Cache-Control'] = 'no-cache';
axios.defaults.xsrfCookieName = undefined;
axios.defaults.baseURL = `${location.protocol}//${location.host}${getTenant(location.pathname)}`;
axios.interceptors.request.use((config) => {
  if (config.url.startsWith('/secure')) {
    config.baseURL = `${location.protocol}//${location.host}`;
    return config;
  }

  const urlTenant: string = getTenant(location.pathname);
  const actionPathTenant: string = getTenant(config.url);

  if(urlTenant !== actionPathTenant) {
    config.url = config.url.replace(actionPathTenant, '');
  }

  config.url = config.url.replace(urlTenant, '/');

  return config;
});
export default axios;
