import KeenSlider, { KeenSliderInstance, KeenSliderOptions } from 'keen-slider';
import { hide } from './easyfy.utils';

export interface ISlider {
  keenConfig?: KeenSliderOptions;
  thumbnailConfig?: IKeenThumbnailConfig;
  navigation?: boolean;
}

export interface IKeenThumbnailConfig {
  container: string;
}

const slideConfigdefault: KeenSliderOptions = {
  loop: false,
  breakpoints: {
    '(min-width: 400px)': {
      slides: { perView: 1.4, spacing: 5, origin: "center" },
    },
    '(min-width: 768px)': {
      slides: { perView: 3.2, spacing: 5 },
    },
    '(min-width: 1024px)': {
      slides: { perView: 3.2, spacing: 5 },
    },
    '(min-width: 1500px)': {
      slides: { perView: 4.2, spacing: 5 },
    },
    '(min-width: 1700px)': {
      slides: { perView: 4, spacing: 5 },
    },
  },
  mode: 'snap',
  dragSpeed: 1,
  renderMode: 'performance',
  slides: { perView: 2, spacing: 0},
};

export const slider = (container: string, config?: ISlider, autoSlider: boolean = false) => {
  document.querySelectorAll<any>(container)?.forEach((item) => {
    let initial = 0;

    if (item?.dataset?.sortorder == "isRandom") initial = Math.floor(Math.random() * item?.children?.length);
    if (container == "[data-bannerslider]") {
      config.keenConfig = {
        loop: true,
        initial: initial
      }
    }
    if (item?.querySelector('.keen-slider__slide')?.children?.length) {
      const keenSlider = new KeenSlider(
        item,
        config?.keenConfig ?? slideConfigdefault,
        config?.navigation ? autoSlider ? [KeenNavigationWithAutoSlider] : [KeenNavigation] : []
      );

      if (config?.thumbnailConfig && config?.thumbnailConfig?.container?.length > 0) {
        mountThumbnailSlider(config, keenSlider);
      }

      return keenSlider;
    } else {
      if (document?.body?.classList?.contains('admin-mode') && item.hasAttribute('data-productslider')) {
        return false;
      }

      if (item) hide(item);
    }
  });
};

const mountThumbnailSlider = (config: ISlider, parentSlider: KeenSliderInstance) => {
  const item: HTMLElement = document.querySelector(config?.thumbnailConfig?.container) as HTMLElement;

  if (item?.querySelector('.keen-slider__slide')?.children?.length) {
    new KeenSlider(
      config?.thumbnailConfig?.container,
      {
        initial: 0,
        slides: {
          perView: 4,
          spacing: 10,
        },
      },
      [KeenThumbnailPlugin(parentSlider)]
    );
  } else {
    if (item) hide(item);
  }
};

const KeenThumbnailPlugin = (main: KeenSliderInstance) => {
  return (slider) => {
    const removeActive = () => {
      slider.slides.forEach((slide) => {
        slide.classList.remove('active');
      });
    };
    const addActive = (idx) => {
      slider.slides[idx].classList.add('active');
    };

    const addClickEvents = () => {
      slider.slides.forEach((slide, idx) => {
        slide.addEventListener('click', () => {
          main.moveToIdx(idx);
        });
      });
    };

    slider.on('created', () => {
      addActive(slider.track.details.rel);
      addClickEvents();
      main.on('animationStarted', (main) => {
        removeActive();
        const next = main.animator.targetIdx || 0;
        addActive(main.track.absToRel(next));
        slider.moveToIdx(next);
      });
    });
  };
};

const KeenNavigationWithAutoSlider = (slider: KeenSliderInstance) => {
  return KeenNavigation(slider, true);
}

const KeenNavigation = (slider: KeenSliderInstance, autoSlider: boolean = false) => {
  let wrapper, dots, arrowLeft, arrowRight;
  let timeout;
  if (slider.slides.length == 1) autoSlider = false;
  let isUpsellSection = slider.container.closest("[data-container='upsell']") != null;

  const clearNextTimeout = () => {
    clearTimeout(timeout);
  }
  const nextTimeout = () => {
    let slideTime = 7000;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      slider.next();
    }, slideTime);
  }

  const markup = (remove: boolean = false) => {
    wrapperMarkup(remove);
    dotMarkup(remove);
    arrowMarkup(remove);
  };

  const removeElement = (elment) => {
    elment?.parentNode?.removeChild(elment);
  };

  const createDiv = (className) => {
    var div = document.createElement('div');
    var classNames = className.split(' ');
    classNames.forEach((name) => div.classList.add(name));
    return div;
  };

  const arrowMarkup = (remove) => {
    if (remove) {
      removeElement(arrowLeft);
      removeElement(arrowRight);
      return;
    }
    let n = autoSlider ? 1 : 3;
    if (slider.slides.length <= n) return;
    arrowLeft = createDiv('arrow arrow--left');
    arrowLeft.addEventListener('click', () => slider.prev());
    arrowRight = createDiv('arrow arrow--right');
    arrowRight.addEventListener('click', () => slider.next());

    wrapper.appendChild(arrowLeft);
    wrapper.appendChild(arrowRight);
  };

  const wrapperMarkup = (remove) => {
    if (remove) {
      var parent = wrapper.parentNode;
      if (wrapper && parent) {
        while (wrapper.firstChild) parent.insertBefore(wrapper.firstChild, wrapper);
        removeElement(wrapper);
      }
      return;
    }

    wrapper = createDiv('navigation-wrapper');
    slider.container.parentNode.appendChild(wrapper);
    wrapper.appendChild(slider.container);
  };

  const dotMarkup = (remove) => {
    if (remove) {
      removeElement(dots);
      return;
    }
    if (slider.slides.length <= 1) return;
    dots = createDiv('dots');
    slider.track.details.slides.forEach((_e, idx) => {
      if (slider.track.details.maxIdx > 0 && (!isUpsellSection || idx <= slider.track.details.maxIdx) ) {
        var dot = createDiv('dot');
        dot.addEventListener('click', () => slider.moveToIdx(idx));
        dots.appendChild(dot);
      } 
    });
    wrapper.appendChild(dots);
  };

  const updateClasses = () => {
    var slide = slider.track.details.rel;

    if (!autoSlider) {
      slide === 0 ? arrowLeft?.classList.add('arrow--disabled') : arrowLeft?.classList.remove('arrow--disabled');
      slide === slider.track.details.maxIdx
        ? arrowRight?.classList.add('arrow--disabled')
        : arrowRight?.classList.remove('arrow--disabled');

    }

    if (dots) {
      Array.from(dots.children).forEach((dot: HTMLElement, idx: number) => {
        idx === slide ? dot.classList.add('dot--active') : dot.classList.remove('dot--active');
      });
    }
  };

  slider.on('created', () => {
    markup();
    updateClasses();
    if (autoSlider) {
      nextTimeout();
    }
  });

  if (autoSlider) {
    slider.on("dragStarted", clearNextTimeout);
    slider.on("animationEnded", nextTimeout);
    slider.on("updated", nextTimeout);
  }

  slider.on('optionsChanged', () => {
    if (autoSlider) {
      markup(true);
      markup();
    }

    updateClasses();
  });

  slider.on('slideChanged', () => {
    updateClasses();
  });

  slider.on('destroyed', () => {
    markup(true);
  });
};
