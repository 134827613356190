import { element, elements } from './easyfy.core';
import mobileDetect from 'mobile-detect';

const tenantRegex = /^\/[a-z]{2}(\/|$)/g;
const mailRegexp =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export interface Attribute {
  name: string;
  value: string;
}

export const detector = new mobileDetect(window.navigator.userAgent);

// exemple
// findKey(
//   {
//     barney: { age: 36, active: true },
//     fred: { age: 40, active: false },
//     pebbles: { age: 1, active: true }
//   },
//   o => o['active']
// ); // 'barney
export const findKey = (obj: any, fn: any) => Object.keys(obj).find((key) => fn(obj[key], key, obj));

// exemple
// findLast([1, 2, 3, 4], n => n % 2 === 1); // 3
export const findLast = (arr: Array<any>, fn: any) => arr.filter(fn).pop();

// exemple
// all([4, 2, 3], x => x > 1); // true
// all([1, 2, 3]); // true
export const all = (arr: Array<any>, fn = Boolean) => arr.every(fn);

// exemple
// allEqual([1, 2, 3, 4, 5, 6]); // false
// allEqual([1, 1, 1, 1]); // true
export const allEqual = (arr: Array<any>) => arr.every((val) => val === arr[0]);

// exemple
// arrayToHtmlList('#myListID', 'li' ,['item 1', 'item 2']);
export const arrayToHtmlList = (selector: string, elementType: string, arr: Array<any>) =>
  ((el) => (
    (el = document.querySelector(selector)),
    (el.innerHTML += arr.map((item) => `<${elementType}>${item}</${elementType}>`).join(''))
  ))();

// exemple
//hasClass(document.querySelector('p.special'), 'special'); // true
export const hasClass = (el: HTMLElement, className: string) => el?.classList?.contains(className);

export const isDisabled = (el: HTMLElement) => el?.getAttribute('disabled')?.length > 0;

// exemple
// isBrowserTabFocused(); // true
export const isBrowserTabFocused = () => !document.hidden;

// exemple
// isNil(null); // true
// isNil(undefined); // true
export const isNil = (val: any) => val === undefined || val === null;

// exemple
// isNull(null); // true
export const isNull = (val: any) => val === null;

export const isNullOrUndefined = (val: unknown) => val === undefined || val === null;

// exemple
// isNumber('1'); // false
// isNumber(1); // true
export const isNumber = (n: any) => {
  return !isNaN(parseFloat(n)) && isFinite(n);
};

// exemple
// isObject([1, 2, 3, 4]); // true
// isObject([]); // true
// isObject(['Hello!']); // true
// isObject({ a: 1 }); // true
// isObject({}); // true
// isObject(true); // false
export const isObject = (obj: any) => obj === Object(obj);

// exemple
// isObjectLike({}); // true
// isObjectLike([1, 2, 3]); // true
// isObjectLike(x => x); // false
// isObjectLike(null); // false
export const isObjectLike = (val: any) => val !== null && typeof val === 'object';

// exemple
// isPlainObject({ a: 1 }); // true
// isPlainObject(new Map()); // false
// export const isPlainObject = (val: any) =>
//   !!val && typeof val === "object" && val.export && constructor === Object;

// exemple
// isString('10'); // true
export const isString = (val: any) => typeof val === 'string';

// exemple
// isUndefined(undefined); // true
export const isUndefined = (val: any) => val === undefined;

// exemple
// parse string into boolean
export const parseToBoolean = (val: string) => isString(val) && val?.toLowerCase() === 'true';

// exemple
// isValidJSON('{"name":"Adam","age":20}'); // true
// isValidJSON('{"name":"Adam",age:"20"}'); // false
// isValidJSON(null); // true
export const isValidJSON = (str: string) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const replaceHtml = (element: HTMLElement, html: string) => {
  if (element?.outerHTML) {
    element.outerHTML = html;
  } else {
    let tmpElement = document.createElement('div');
    tmpElement.innerHTML = html;
    element?.parentNode?.replaceChild(tmpElement, element);
  }
};

export const replaceELement = (element: HTMLElement, replaceElement: HTMLElement) => {
  element?.parentNode?.replaceChild(replaceElement, element);
};

export const htmlToElement = (html: string): HTMLElement => {
  var template = document.createElement('template');
  html = html.trim(); // Never return a text node of whitespace as the result
  template.innerHTML = html;
  return template.content.firstChild as HTMLElement;
};

// exemple
// matches({ age: 25, hair: 'long', beard: true }, { hair: 'long', beard: true }); // true
// matches({ hair: 'long', beard: true }, { age: 25, hair: 'long', beard: true }); // false
export const matches = (obj: any, source: any) =>
  Object.keys(source).every((key) => obj.hasOwnProperty(key) && obj[key] === source[key]);

// exemple
// randomHexColorCode(); // "#e34155"
export const randomHexColorCode = () => {
  let n = (Math.random() * 0xfffff * 1000000).toString(16);
  return '#' + n.slice(0, 6);
};

// exemple
// redirect('https://google.com');
export const redirect = (url, asLink = true) => (asLink ? (window.location.href = url) : window.location.replace(url));

// exemple
// scrollToTop();
export const scrollToTop = () => {
  const c = document.documentElement.scrollTop || document.body.scrollTop;
  //scrollTo(0, c - c / 8);
  scrollTo(0, 0);
};

// exemple
// scrollToTop();
export const scrollToElement = (element?: HTMLElement, yCoords?: number) => {
  if (element) {
    yCoords = element.offsetTop || 0;
  }
  scrollTo(0, yCoords);
};

export const scrollTo = (x: number, y: number) => {
  window.scrollTo(x, y);
};

// exemple
// setStyle('p', 'font-size', '20px');
export const setStyle = (selector: string, ruleName: string, val: string) => (element(selector).style[ruleName] = val);

// exemple
// getStyle('p', 'font-size');
export const getStyle = (selector: string, ruleName: string) => element(selector).style[ruleName];

// exemple
// show(...document.querySelectorAll("img")); // Shows all <img> elements on the page
export const showAll = (el: Array<HTMLElement>, displayType?: string) =>
  [...el].forEach((e) => (e.style.display = displayType || 'block'));

// exemple
// hide(document.querySelectorAll('img')); // Hides all <img> elements on the page
export const hideAll = (el: Array<HTMLElement> | NodeListOf<HTMLElement>) =>
  el.forEach((e) => (e.style.display = 'none'));

export const show = (selector: string | Element, displayType?: string) =>
  showAll([selector instanceof Element ? selector : element(selector)], displayType);

export const hide = (selector: string | Element) =>
  hideAll([selector instanceof Element ? selector : element(selector)]);

export const toggleShow = (selector: string, displayType?: string) => {
  const el = element(selector);
  visible(el) ? hideAll([el]) : showAll([el], displayType);
};

export const removeCss = (
  el: HTMLElement | Element | Array<HTMLElement> | NodeListOf<HTMLElement>,
  className: string | Array<string>
) => {
  if (Array.isArray(el) || el instanceof NodeList) {
    el.forEach((element) => {
      Array.isArray(className) ? element?.classList?.remove(...className) : element?.classList?.remove(className);
    });
  } else {
    Array.isArray(className)
      ? (el as HTMLElement | Element)?.classList?.remove(...className)
      : (el as HTMLElement | Element)?.classList?.remove(className);
    return el;
  }
};

export const addCss = (el: HTMLElement | Element, className: string | Array<string>) => {
  if (Array.isArray(className)) {
    el?.classList?.add(...className);
    return el;
  }

  if (!hasClass(el as HTMLElement, className)) {
    el?.classList?.add(className);
  }

  return el;
};

export const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const visible = (element: HTMLElement): boolean => {
  return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
};

// exemple
// smoothScroll('#fooBar'); // scrolls smoothly to the element with the id fooBar
// smoothScroll('.fooBar'); // scrolls smoothly to the first element with a class of fooBar
export const smoothScroll = (element: any) =>
  document.querySelector(element)?.scrollIntoView({
    behavior: 'smooth',
  });

// exemple
// splitLines('This\nis a\nmultiline\nstring.\n'); // ['This', 'is a', 'multiline', 'string.' , '']
export const splitLines = (str: string) => str.split(/\r?\n/);

// exemple
// stripHTMLTags('<p><em>lorem</em> <strong>ipsum</strong></p>'); // 'lorem ipsum'
export const stripHTMLTags = (str: string) => str.replace(/<[^>]*>/g, '');

// exemple
// sum(1, 2, 3, 4); // 10
// sum(...[1, 2, 3, 4]); // 10
export const sum = (...arr: any) => [...arr].reduce((acc, val) => acc + val, 0);

// exemple
// timeTaken(() => Math.pow(2, 10)); // 1024, (logged): timeTaken: 0.02099609375ms
export const timeTaken = (callback: any) => {
  console.time('timeTaken');
  const r = callback();
  console.timeEnd('timeTaken');
  return r;
};

export const replaceWith = (element: HTMLElement, replaceWithElement: HTMLElement) => {
  element.replaceWith(replaceWithElement);
};

// exemple
// toCurrency(123456.789, "EUR"); // €123,456.79  | currency: Euro | currencyLangFormat: Local
// toCurrency(123456.789, 'USD', 'en-us'); // $123,456.79  | currency: US Dollar | currencyLangFormat: English (United States)
// toCurrency(123456.789, 'USD', 'fa'); // ۱۲۳٬۴۵۶٫۷۹ ؜$ | currency: US Dollar | currencyLangFormat: Farsi
// toCurrency(322342436423.2435, 'JPY'); // ¥322,342,436,423 | currency: Japanese Yen | currencyLangFormat: Local
// toCurrency(322342436423.2435, 'JPY', 'fi'); // 322 342 436 423 ¥ | currency: Japanese Yen | currencyLangFormat: Finnish
export const toCurrency = (n: any, curr: any, languageFormat = undefined) =>
  Intl.NumberFormat(languageFormat, {
    style: 'currency',
    currency: curr,
  }).format(n);

// exemple
// toDecimalMark(12305030388.9087); // "12,305,030,388.909"
// toDecimalMark(12305030388.9087, "en-us"); // "12,305,030,388.909"
export const toDecimalMark = (num: any, languageFormat = undefined) => num.toLocaleString(languageFormat);

// exemple
// toggleClass('p.special', 'special'); // The paragraph will not have the 'special' class anymore
export const toggleClass = (selector: string | HTMLElement, className: any) => {
  if (selector instanceof HTMLElement) selector.classList.toggle(className);
};

export const debounce = <F extends (...args: any[]) => any>(func: F, waitFor: number) => {
  let timeout;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};

export const isFunction = (f: Function) => {
  return f && {}.toString.call(f) === '[object Function]';
};

export const Id = (len: number) => {
  return '_' + Math.random().toString(36).substr(2, len);
};

export const toggleClassBySelector = (selector: string, className: string, toggle: boolean) => {
  let els = elements(selector);
  els.forEach((item) => {
    if (toggle) {
      addCss(item, className);
    } else {
      removeCss(item, className);
    }
  });
};

export const setAttributes = (element: HTMLElement, attributes: Array<Attribute>): HTMLElement | Element => {
  attributes.forEach((item) => {
    if (item?.name && item?.value) {
      element.setAttribute(item.name, item.value);
    }
  });
  return element;
};

export const offset = (element: HTMLElement): { top: number; left: number } => {
  const rect = element.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
};

export const winHeight = () =>
  window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export const winWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

export const isBetweenDates = (startDate: Date, endDate: Date, checkDate: Date) => {
  if (checkDate > startDate && checkDate < endDate) {
    return true;
  }
  return false;
};

//todo: support other short date string formats other then 2020-01-01
export const getDate = (date: string) => {
  if (date !== '') {
    var d = date.split('-');
    if (d.length == 3) {
      return new Date(parseInt(d[0]), parseInt(d[1]) - 1, parseInt(d[2]));
    }
  }
  return new Date();
};

export const getSelectedValue = (select: HTMLSelectElement) => {
  if (select) {
    const selectedOption = select.options[select.selectedIndex];
    return { text: selectedOption.text, value: selectedOption.value };
  }
  return;
};

export const removeHash = () => {
  var scrollV,
    scrollH,
    loc = window.location;
  if ('pushState' in history) {
    history.pushState('', document.title, loc.pathname + loc.search);
  } else {
    // Prevent scrolling by storing the page's current scroll offset
    scrollV = document.body.scrollTop;
    scrollH = document.body.scrollLeft;

    loc.hash = '';

    // Restore the scroll offset, should be flicker free
    document.body.scrollTop = scrollV;
    document.body.scrollLeft = scrollH;
  }
};

export const truncate = (text: string, length: number) =>
  text.length > length ? `${text.substring(0, length)}...` : text;

export const truncateVisible = (text: string, length: number) =>
  text.length > length
    ? `${text.substring(0, length)}...<div class="hidden">${text.substring(length, text.length)}</div>`
    : text;

interface viewPort {
  mobile: boolean;
  tablet: boolean;
  desktop: boolean;
}

export const viewWidth = () => Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);

export const viewPort = (): viewPort => {
  const vw = viewWidth();
  const mobileViewport: number = 480;
  const tableViewport: number = 768;
  const desktopViewport: number = 1024;
  return {
    mobile: vw >= 0 && vw <= tableViewport,
    tablet: vw >= mobileViewport && vw <= desktopViewport,
    desktop: vw >= desktopViewport,
  };
};

export const getTenant = (str: string): string => {
  const found = (str || '').match(tenantRegex);
  return found?.length > 0 ? `${found[0].length === 3 ? found[0] : found[0].substr(0, 3)}/` : '';
};

export const diffMinutes = (dt2, dt1) => {
  let diff = (dt2.getTime() - dt1.getTime()) / 1000;
  diff /= 60;
  return Math.abs(Math.round(diff));
};

export const isEmail = (email: string) => mailRegexp.test(email);

export const domLoaded = (fn: Function) => {
  let fns = [],
    listener,
    doc = document,
    readystatechange = 'readystatechange',
    loaded = /^loaded|^i|^c/.test(doc.readyState);

  if (!loaded)
    doc.addEventListener(
      'readystatechange',
      (listener = () => {
        if (document.readyState === 'complete') {
          doc.removeEventListener(readystatechange, listener);
          while ((listener = fns.shift())) listener();
        }
      })
    );

  loaded ? setTimeout(fn, 0) : fns.push(fn);
};

export const pageReload = (timeOut: number = 500) => {
  setTimeout(function () {
    location.reload();
  }, timeOut);
};

export const GetCookie = (cname) => {
  return document.cookie
    .split('; ')
    .find((row) => row.startsWith(cname))
    .split('=')[1];
};

export const SetCookie = (cname, cvalue, exdays) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
};

export const isCheckout = (): boolean => {
  return location.pathname.includes('checkout');
};

export const isVisibleOnScreen = (element): boolean => {
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};